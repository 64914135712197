<template>
    <Survey v-if="survey" :survey="survey" />
  </template>
  
  <script>
  import 'survey-core/defaultV2.min.css';
  import { Model } from 'survey-core';
  import { Survey } from 'survey-vue-ui';
  import { ref, computed, getCurrentInstance, watch } from '@vue/composition-api';
  import useAppConfig from '@core/app-config/useAppConfig';
  import darkTheme from '@/assets/surveyThemes/survey_theme_dark.json';
  import lightTheme from '@/assets/surveyThemes/survey_theme_light.json';
  import router from '@/router';
  
  const themes = {
    light: lightTheme,
    dark: darkTheme
  };
  
  export default {
    components: {
      Survey
    },
    setup() {
      const { skin } = useAppConfig();
      const surveySkin = computed(() => skin.value);
      
      const id = router.currentRoute.params.id;
      const item = ref(null);
      const survey = ref(null);
      
      const { proxy } = getCurrentInstance();
  
      proxy.$http.get(`/questionnaires/${id}`).then((res) => {
        item.value = res.data;
        const surveyJSON = item.value.body;
        survey.value = new Model(surveyJSON);
        survey.value.data = item.value.answers;
        survey.value.applyTheme(themes[surveySkin.value]);
        survey.value.mode = "display";
      });
  
      watch(surveySkin, (newSkin) => {
        if (survey.value) {
          survey.value.applyTheme(themes[newSkin]);
        }
      });
  
      return { surveySkin, survey };
    }
  };
  </script>
  